<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex">
        <v-select
          class="flex-shrink-1"
          v-show="!relativeDate && !date"
          prepend-icon="mdi-calendar"
          label="Date Type"
          v-model="relativeDate"
          :items="relativeItems"
        ></v-select>
        <v-text-field
          class="flex-grow-1"
          v-show="relativeDate == 'Custom Date' || date"
          v-model="dateText"
          :label="
            options.range
              ? 'Select Date Range'
              : options.range
              ? 'Select Annual Date'
              : 'Select Date'
          "
          :prepend-icon="options.range ? 'mdi-calendar-range' : 'mdi-calendar'"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="dateRules"
          @change="change"
          clearable
          :required="!options.optional"
        ></v-text-field>
      </div>
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      show-adjacent-months
      :no-title="true"
      :range="options.range"
      :type="options.month ? 'month' : 'date'"
      :max="maxDays"
      :min="minDays"
    >
      <v-spacer></v-spacer>
      <v-btn text color="secondary" @click="cancel"> Cancel </v-btn>
      <v-btn text color="primary" @click="change"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import clone from "../../utils/clone";
import dayjs from "dayjs";
var isoWeek = require("dayjs/plugin/isoWeek");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

dayjs.extend(isoWeek);
const rangeText = " through ";
export default {
  data() {
    return {
      dateRules: [
        (v) => !!v || "Date is required",
        () => this.valid || "Date is invalid",
      ],
      relativeDate: "",
      valid: false,
      modal: false,
      date: null,
    };
  },
  props: {
    value: {
      type: [String, Array, Object],
    },
    options: {
      type: Object,
      default: () => ({
        annual: false,
        range: false,
        month: false,
        minDays: 100,
        maxDays: 100,
        autoOpen: false,
        keys: null,
      }),
    },
  },
  watch: {
    relativeDate(val) {
      let format = "YYYY-MM-DD";
      let lastWeek = dayjs().startOf("isoWeek").subtract(1, "week");
      let thisWeek = dayjs().startOf("isoWeek");
      let lastMonth = dayjs().startOf("month").subtract(1, "month");
      let thisMonth = dayjs().startOf("month");
      if (!val || val === "Custom Date") {
        this.modal = true;
        return;
      }
      switch (val) {
        case "Yesterday":
          this.date = dayjs().subtract(1, "day").format(format);
          break;
        case "Today":
          this.date = dayjs().format(format);
          break;
        case "Last Week":
          this.date = [
            lastWeek.format(format),
            lastWeek.add(6, "days").format(format),
          ];

          break;
        case "This Week":
          this.date = [
            thisWeek.format(format),
            thisWeek.add(6, "days").format(format),
          ];

          break;
        case "Last Month":
          if (!this.options.range) {
            this.date = lastMonth.format(format);
          } else {
            this.date = [
              lastMonth.format(format),
              lastMonth.add(1, "month").subtract(1, "day").format(format),
            ];
          }

          break;
        case "This Month":
          if (!this.options.range) {
            this.date = thisMonth.format(format);
          } else {
            this.date = [
              thisMonth.format(format),
              thisMonth.add(1, "month").subtract(1, "day").format(format),
            ];
          }

          break;
      }
      if (this.options.range && typeof this.date === "string") {
        this.date = [this.date, this.date];
      }
      this.change();
    },
    "options.autoOpen": {
      immediate: true,
      handler() {
        this.relativeDate = "customDate";
      },
    },
    value: {
      immediate: true,
      handler() {
        this.processValue();
        // if (val) {
        //   if (this.options.key) {
        //     let keys = [].concat(this.options.key);
        //     let values = keys.map((key) => val[key]);
        //     if (!this.options.range) {
        //       values = values[0];
        //     }
        //     this.date = values;
        //   } else {
        //     if (this.options.month && this.options.range && val.length === 2) {
        //       if (val[1].length !== 10) {
        //         this.date[0] = val[0] + "-01";
        //         this.date[1] = dayjs(val[1] + "-01").endOf("month");
        //       } else {
        //         this.date = val;
        //       }
        //     }
        //   }
        // } else {
        //   console.log("CLEAR DATE");
        //   this.date = null;
        // }
      },
    },
    date: {
      immediate: true,
      handler(val, oldVal) {
        this.valid = false;
        console.log("Valid Test", { val, oldVal });
        if (this.options.optional && !val) {
          console.log("Optional");
          this.valid = true;
        } else if (!this.options.optional && !val) {
          this.valid = false;
        } else if (!this.options.range && dayjs(val).isValid()) {
          this.valid = true;
        } else if (this.options.range) {
          this.valid =
            val.length === 2 &&
            dayjs(val[0]).isValid() &&
            dayjs(val[1]).isValid() &&
            dayjs(val[1]).isSameOrAfter(val[0]);
        } else {
          this.valid = dayjs(val).isValid();
        }
        let keys = [].concat(this.options.key);
        let result = keys.reduce((obj, key) => {
          obj[key] = this.valid;
          return obj;
        }, {});
        if (result.undefined !== undefined) {
          result = result.undefined;
        }
        console.log("EMIT VALID", result);
        this.$emit("valid", result);
        if (!val && oldVal) {
          console.log("IS THIS NEEDED");
          this.change();
        }
      },
    },
  },
  methods: {
    processValue() {
      if (this.value) {
        this.date = this.value;
        if (this.options.key) {
          let keys = [].concat(this.options.key);
          let values = keys.map((key) => this.value[key]);
          if (!this.options.range) {
            values = values[0];
          }
          this.date = values;
        } else {
          if (
            this.options.month &&
            this.options.range &&
            this.value.length === 2
          ) {
            if (this.value[1].length !== 10) {
              this.date[0] = this.value[0] + "-01";
              this.date[1] = dayjs(this.value[1] + "-01").endOf("month");
            } else {
              this.date = this.value;
            }
          }
        }
      } else {
        console.log("CLEAR DATE");
        this.date = null;
      }
    },
    cancel() {
      this.date = this.processValue();
      //clone(this.value);
      this.change();
    },
    fixYear(date) {
      if (!this.options.annual) {
        return date;
      }
      if (Array.isArray(date)) {
        return date;
      }
      let parts = clone(date).split("-");
      parts.shift();
      let newDate = dayjs().format("YYYY") + "-" + parts.join("-");
      if (dayjs().isAfter(dayjs(date).endOf("day"))) {
        newDate = dayjs(date).add(1, "year").format("YYYY-MM-DD");
      }
      return newDate;
    },
    change() {
      this.modal = false;

      console.log("change", this.date);
      if (!this.date) {
        this.date = "";
        this.relativeDate = null;
        return;
      }
      if (this.date) {
        this.date = this.fixYear(this.date);
        this.$refs.dialog.save(this.date);
        if (
          this.date &&
          this.options.range &&
          this.options.month &&
          this.date[0].length === 7
        ) {
          this.date[0] = this.date[0] + "-01";
          this.date[1] = dayjs(this.date[1] + "-01")
            .endOf("month")
            .format("YYYY-MM-DD");
        }
        if (
          this.date &&
          this.options.range &&
          this.date.length === 2 &&
          dayjs(this.date[0]).isAfter(this.date[1])
        ) {
          this.date = this.date.reverse();
        }
      }
      let dates = this.date ? [].concat(this.date) : [];
      console.log({ dates }, this.options.key);
      let result = dates.length === 1 ? dates[0] : dates;
      if (this.options.key) {
        let keys = [].concat(this.options.key);
        result = keys.reduce((obj, key, index) => {
          obj[key] = dates[index] || null;
          return obj;
        }, {});
        console.log("EMIT", result, this.options);
        if (result.undefined) {
          result = result.undefined;
        }
      }
      console.log("EMIT", result, this.options);
      this.$emit("input", result);
    },
  },
  computed: {
    relativeItems() {
      let items = ["Custom Date"];
      if (this.options.annual) {
        //do nothing
      } else if (this.options.month) {
        items = ["Custom Date", "Last Month", "This Month"];
      } else if (this.options.range) {
        items = items.concat([
          "Yesterday",
          "Today",
          "Last Week",
          "Last Month",
          "This Week",
          "This Month",
        ]);
      } else {
        items = [...items, ...["Yesterday", "Today"]];
      }
      return items;
    },
    minDays() {
      if (this.options.minDays === undefined) return;
      return dayjs()
        .subtract(this.options.minDays, "days")
        .format("YYYY-MM-DD");
    },
    maxDays() {
      console.log("MAX DAYS", this.options);
      if (this.options.maxDays === undefined) return;
      return dayjs().add(this.options.maxDays, "days").format("YYYY-MM-DD");
    },
    dateText: {
      get() {
        if (!this.date) {
          return this.options.range ? [] : "";
        }
        let format = this.options.annual
          ? "MMM D"
          : this.options.month
          ? "MMM YYYY"
          : "YYYY-MM-DD";
        let dates = [].concat(this.date || []);
        return dates.map((date) => dayjs(date).format(format)).join(rangeText);
      },
      set(val) {
        console.log(val);
        if (!val) {
          // this.value = null
          this.date = null;
        }
      },
    },
  },
  mounted() {
    // if (this.)
  },
};
</script>

<style>
</style>